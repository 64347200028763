import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    CircularProgress,
    Popover,
    Tooltip,
} from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import FilterChip, { CategoryFilterChip } from '../../components/FilterChip';
import PhoneNumber from '../../components/PhoneNumber';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import _debounce from 'lodash.debounce';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import OrdersList from '../../components/OrdersList';
import { useNavigate } from 'react-router-dom';

function ComplimentaryOrder() {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const token = localStorage.getItem("token");
    const outletId = localStorage.getItem("outletId");
    const [selectedChipIndex, setSelectedChipIndex] = useState(null);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [orders, setOrders] = useState([]); // New state for orders
    const [selectedAddons, setSelectedAddons] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const storedOutletData = localStorage.getItem('outletData');
    const outletData = JSON.parse(storedOutletData);
    const [quantity, setQuantity] = useState(1);
    const [addingToCart, setAddingToCart] = useState(false);
    const [cartUpdated, setCartUpdated] = useState(false);
    const [hasCartItems, setHasCartItems] = useState(false);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        handleOpenModal();
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedAddons({});
    };

    const getCategoriesList = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCategories(response.data.data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleChipClick = async (categoryId, index) => {
        if (index === selectedChipIndex) {
            setSelectedChipIndex(null);
            await getProductsList(searchQuery, null);
        } else {
            setSelectedChipIndex(index);
            await getProductsList(searchQuery, categoryId);
        }
    };

    const getProductsList = async (searchText, categoryId) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API.BASE_URL}product/List`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { search: searchText, category: categoryId },
            });
            setProducts(response.data.data.products);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async (searchText) => {
        setSearchQuery(searchText);
        if (selectedChipIndex !== null) {
            const categoryId = categories[selectedChipIndex]._id;
            await getProductsList(searchText, categoryId);
        } else {
            await getProductsList(searchText, null);
        }
    };

    const handleAddToOrder = () => {
        const addons = selectedProduct?.hasAddon
            ? selectedProduct.addons.map((addon) => ({
                addonId: addon._id,
                selectedValue: selectedAddons[addon._id] !== undefined
                    ? addon.addonValues[selectedAddons[addon._id]]
                    : null,
            }))
            : [];

        const orderItem = {
            productId: selectedProduct._id,
            name: selectedProduct.name,
            offerPrice: selectedProduct.offerPrice,
            quantity: 1, // Initialize quantity to 1
            addons,
        };

        setOrders((prevOrders) => [...prevOrders, orderItem]);
        handleCloseModal();
        toast.success("Product added to order!");
    };

    const handleAddonChipClick = (addonId, valueIndex) => {
        setSelectedAddons((prevState) => ({
            ...prevState,
            [addonId]: selectedProduct.addons.find((addon) => addon._id === addonId).addonValues[valueIndex],
        }));
    };

    const handleAddToCart = async () => {
        // console.log('selectedAddons', selectedAddons);
        try {
            const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
                _id: addonId,
                selectedValue: selectedAddons[addonId]?.value,
                addonValueId: selectedAddons[addonId]?._id,
            }));
            const cartItem = {
                ordersType: "PICKUP",
                // outlet: outletId,
                cartItems: [
                    {
                        product: selectedProduct._id,
                        quantity,
                        addons: addonsArray?.length > 0 ? addonsArray : undefined,
                    },
                ],
            };
            const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Product added to cart!!!');
            setCartUpdated((prev) => !prev);
            handleCloseModal();
            console.log(response.data);
        } catch (error) {
            console.error('Error adding product to cart:', error?.response?.data?.message);
            toast.error(error?.response?.data?.message || 'Failed to add product to cart');
        } finally {
            setAddingToCart(false);
        }
    };

    const fetchCartData = async () => {
        try {
            const response = await axios.get(
                `${API.BASE_URL}cart/userCart/${outletData._id}/PICKUP`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error) {
            console.log('Cart Screen Error', error?.response?.data);
            toast.error(error?.response?.data)
        }
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const calculateTotalPrice = () => {
        const basePrice = selectedProduct?.offerPrice || 0;
        const addonsPrice = Object.values(selectedAddons)
            .reduce((total, addon) => total + (addon?.offerPrice || 0), 0);
        return basePrice + addonsPrice;
    };

    useEffect(() => {
        getProductsList();
        getCategoriesList();
    }, []);

    return (
        <>
            <Box>
                <Grid container className="flex-section">
                    <Grid item md={8} sx={{ backgroundColor: '#F6F6F6', paddingTop: '120px', height: '100vh', overflowY: 'auto' }} className="flex-no-scroll">
                        <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px', marginRight: '10px', }} className="custom-scrollbar">
                            <TextField
                                fullWidth
                                sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                                placeholder="Search"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchQuery}
                                onChange={(e) => { setSearchQuery(e.target.value); handleSearch(e.target.value); }}
                            />
                            <Grid container justifyContent="center">
                                <Grid item md={12} sx={{ margin: '15px 0', overflowX: 'auto', whiteSpace: 'nowrap' }} >
                                    <Grid container justifyContent="center">
                                        {categories?.filter(category => outletData?.isEventOutlet || !category?.isEventCategory).map((category, index) => (
                                            <Grid item md={2} sx={{ padding: '4px 4px 4px 4px'}}>
                                                <CategoryFilterChip
                                                    key={category?._id}
                                                    filterText={category?.name}
                                                    isSelected={selectedChipIndex === index}
                                                    onClick={() => handleChipClick(category?._id, index)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                {loading ? (
                                    <Grid item md={3} sx={{ margin: '20px', textAlign: 'center' }}>
                                        <CircularProgress sx={{ color: '#000', margin: '10px 0 15px 0' }} />
                                        <br />
                                        <Typography variant="paragraph">Fetching Products...</Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        {products?.length > 0 ? (
                                            products?.map((product) => (
                                                <Grid
                                                    item
                                                    md={3}
                                                    key={product?._id}
                                                    className="cursor-p"
                                                    onClick={product.isExhausted ? handlePopoverOpen : () => handleProductClick(product)}
                                                    sx={{ backgroundColor: product?.isExhausted ? '#FECBCB' : 'white', margin: '20px', textAlign: 'center', padding: '18px 0 18px 0', borderRadius: '12px', position: 'relative' }}
                                                >
                                                    {product?.isExhausted && (
                                                        <Tooltip title="Product out of stock" arrow>
                                                            <ReportProblemIcon sx={{ position: 'absolute', color: 'red', top: '10px', right: '10px' }} />
                                                        </Tooltip>
                                                    )}
                                                    <img
                                                        src={product?.displayImage}
                                                        alt={product?.name}
                                                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = Images?.product; }}
                                                    />
                                                    <Typography>{product?.name}</Typography>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography sx={{ textAlign: 'center', fontSize: '16px', margin: '20px' }} >No products available. </Typography>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={4} sx={{ height: '100vh', overflowY: 'auto', padding: '10px 0 20px 0' }}>
                        <Box sx={{ marginTop: '110px', padding: '0 30px' }}>
                            <OrdersList cartUpdated={cartUpdated} setHasCartItems={setHasCartItems} />
                            {hasCartItems && (
                                <PrimaryButton
                                    onClick={() => navigate('/complimentaryOrderPayment')}
                                    title="Create Order"
                                    style={{ borderRadius: '30px', marginTop: '20px' }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={openModal}
                    onClose={handleCloseModal}
                >
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <DialogTitle>{selectedProduct?.name}</DialogTitle>
                        <DialogContent>
                            {selectedProduct?.hasAddon && (
                                <Grid
                                    container
                                    sx={{
                                        border: '1px solid #000',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        padding: '0px 20px',
                                        alignItems: 'center',
                                    }}
                                >
                                    {selectedProduct?.addons?.map((addon) => (
                                        <React.Fragment key={addon?._id}>
                                            <Grid
                                                item
                                                md={2}
                                                sx={{
                                                    alignItems: 'center',
                                                    borderRight: '1px solid #000',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        alignSelf: 'center',
                                                    }}
                                                >
                                                    {addon?.addonTitle}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                md={10}
                                                gap={4}
                                                display="flex"
                                                sx={{
                                                    padding: '10px 0px 10px 15px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {addon?.addonValues?.map((addonValue, index) => (
                                                    <Box
                                                        key={addonValue?._id}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            margin: '5px',
                                                        }}
                                                    >
                                                        <FilterChip
                                                            key={addonValue?._id}
                                                            filterText={addonValue?.value}
                                                            isSelected={selectedAddons[addon?._id]?._id === addonValue?._id}
                                                            onClick={() => handleAddonChipClick(addon?._id, index)}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: '500',
                                                                color: 'green',
                                                                marginTop: '5px',
                                                            }}
                                                        >
                                                            ₹ {addonValue?.offerPrice || 0}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            )}
                            <Grid
                                container
                                justifyContent="space-around"
                                sx={{ paddingTop: '10px' }}
                            >
                                <Grid item md={4}>
                                    <Typography sx={{ fontSize: '22px' }}>Item Total</Typography>
                                    <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>
                                        ₹ {calculateTotalPrice()}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} style={{ padding: '10px 0 10px 0' }}>
                                    <PrimaryButton
                                        title={addingToCart ? 'Adding...' : 'Add to Cart'}
                                        onClick={handleAddToCart}
                                        disabled={addingToCart}
                                        style={{ marginTop: '20px' }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
            </Box>
        </>
    );
}

export default ComplimentaryOrder;
