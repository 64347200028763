import React, { useEffect, useState } from 'react';
import { Box, Toolbar, Typography, TextField, Grid, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PrimaryButton from '../../components/PrimaryButton';
import axios from 'axios';
import { API } from '../../utils/Api';
import Images from '../../utils/Images';

function VerifyComplimentaryOrderPayment() {
    const navigate = useNavigate();
    const location = useLocation();
    const { remark } = location.state || {};
    const token = localStorage.getItem('token');
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isOtpFieldVisible, setIsOtpFieldVisible] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [makingTime, setMakingTime] = useState([]);
    const [items, setItems] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [timer, setTimer] = useState(10);
    const [userDetail, setUserDetail] = useState(JSON.parse(localStorage.getItem('userDetail')));
    const outletId = localStorage.getItem("outletId");
    const userRole = localStorage.getItem("role");
    const [otpSent, setOtpSent] = useState(false);
    
    const schema = Yup.object().shape({
        // email: Yup.string().email('Invalid email').required('Email is required'),
        otp: Yup.string().required('OTP is required'),
    });

    const handleInputChange = (field, value) => {
        if (field === 'otp') {
            setOtp(value);
        }
        //  else if (field === 'email') {
        //     setEmail(value);
        // }
        setErrors({ ...errors, [field]: '' });
    };

    const validateField = async (field, value) => {
        try {
            await Yup.reach(schema, field).validate(value);
            return true;
        } catch (error) {
            setErrors({ ...errors, [field]: error.message });
            return false;
        }
    };

    const handleSendEmailOtp = async () => {
        setBtnDisabled(true);
        try {
            await axios.post(`${API.BASE_URL}order/generateOtp`, {
                email: userDetail?.email
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            toast.success('OTP sent successfully!');
            setIsOtpFieldVisible(true);
        } catch (error) {
            toast.error('Failed to send OTP');
        } finally {
            setBtnDisabled(false);
        }
    };

    const fetchMakingTime = async () => {
        try {
            const responseForMakingTime = await axios.get(`${API.BASE_URL}cart/getMakingTime`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMakingTime(responseForMakingTime?.data?.data);
        } catch (error) {
            console.error('Error in making time API', error.response);
        }
    };

    const fetchCartData = async () => {
        try {
            const response = await axios.get(
                `${API.BASE_URL}cart/userCart/${outletId}/PICKUP`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // const cartItems = response?.data?.cartItems || [];
            // setItems(cartItems);
            setItems(response.data?.data?.cartItems || []);
        } catch (error) {
            console.error("Error fetching cart data:", error);
            toast.error("Failed to load cart data");
        }
    };

    const handleVerifyOtp = async () => {
        const isValidOtp = await validateField('otp', otp);
        if (isValidOtp) {
            setBtnDisabled(true);
            try {
                const parsedOtp = parseInt(otp, 10);
                await axios.post(`${API.BASE_URL}order/verifyOtp`, { otp: parsedOtp }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                toast.success('OTP verified successfully!');
                setShowCart(true);
                fetchCartData();
                startTimer();
            } catch (error) {
                toast.error('Failed to verify OTP');
            } finally {
                setBtnDisabled(false);
            }
        }
    };

    const startTimer = () => {
        let countdown = 10;
        const interval = setInterval(() => {
            if (countdown > 0) {
                setTimer(countdown--);
            } else {
                clearInterval(interval);
                createComplimentaryOrder();
            }
        }, 1000);
    };

    const createComplimentaryOrder = async () => {
        try {
            await axios.post(`${API.BASE_URL}order/createComplimentaryOrder`, {
                "outlet": outletId,
                "readyTime": makingTime?.readyTime,
                "arrivalTimeDurationInMinutes": makingTime?.makingTimeDuration.match(/\d+/)?.[0],
                "paymentMode": "COMPLIMENTARY",
                "remark": remark
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            navigate('/onlineOrders');
            toast.success('Order created successfully!');
        } catch (error) {
            toast.error('Failed to create order');
        }
    };

    const cancelProcess = () => {
        setShowCart(false);
        setTimer(10);
        toast.info('Process cancelled');
        navigate('/complimentaryOrder')
    };

    useEffect(() => {
        handleSendEmailOtp();
        fetchCartData();
        fetchMakingTime();
    }, []);

    return (
        <>
            {!showCart ? (
                <>
                    <Box sx={{ position: 'absolute', top: '40px', left: '10px' }}>
                        <Toolbar />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Grid container justifyContent="center">
                            <Grid item md={4}>
                                <Toolbar />
                                <Toolbar />
                                <Toolbar />
                                <Typography variant="h4" sx={{ marginBottom: '25px' }}>Verify to Create Order</Typography>
                                <Box sx={{ textAlign: 'left' }}>
                                    <label>Email</label>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                                        placeholder="Enter email"
                                        variant="outlined"
                                        value={userDetail?.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                        error={!!errors.email}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    {errors.email && (
                                        <Typography className="errorText" color="error">
                                            {errors.email}
                                        </Typography>
                                    )}
                                </Box>
                                {/* <Grid container justifyContent="center" style={{ marginTop: '27px' }}>
                                    <Grid item xs={12}>
                                        <PrimaryButton
                                            title="Send Email OTP"
                                            disabled={btnDisabled}
                                            onClick={handleSendEmailOtp}
                                            style={{ borderRadius: '30px', fontSize: '18px' }}
                                        />
                                    </Grid>
                                </Grid> */}
                                <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                                    <label>Verification OTP</label>
                                    <TextField
                                        fullWidth
                                        id="otp"
                                        sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                                        placeholder="Enter OTP"
                                        variant="outlined"
                                        value={otp}
                                        onChange={(e) => handleInputChange('otp', e.target.value)}
                                        error={!!errors.otp}
                                    />
                                    {errors.otp && (
                                        <Typography className="errorText" color="error">
                                            {errors.otp}
                                        </Typography>
                                    )}
                                </Box>
                                <Grid container justifyContent="center" style={{ marginTop: '27px' }}>
                                    <Grid item xs={12}>
                                        <PrimaryButton
                                            title="Verify OTP"
                                            disabled={btnDisabled}
                                            onClick={handleVerifyOtp}
                                            style={{ borderRadius: '30px', fontSize: '18px' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                    <Toolbar />
                    <Toolbar />
                    <Typography variant="h5">Order Items</Typography>
                    {items.map((item, index) => (
                        <Grid container spacing={2} sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid
                                item
                                xs={4}
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    padding: '10px',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={item?.product?.displayImageUrl || Images.xpanse}
                                    alt={item?.product?.name}
                                    sx={{ width: '80px', height: '80px', borderRadius: '8px', marginRight: '15px' }}
                                />
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {item?.product?.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Quantity: {item?.quantity}
                                    </Typography>
                                    {item?.addons && item?.addons?.length > 0 && (
                                        <Grid container>
                                            {item?.addons?.map((addon, addonIndex) => (
                                                <Grid item xs={12} key={addonIndex} className="attributes">
                                                    {addon?.selectedValue
                                                        ? `${addon?.selectedValue} ₹${addon?.offerPrice || 0}`
                                                        : "No Addon"}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                    <Typography variant="h6" sx={{ marginTop: '20px', color: 'darkgreen' }}>
                        Order will be created in {timer} seconds
                    </Typography>
                    <Button variant="contained" color="error" onClick={cancelProcess} sx={{ marginTop: '20px' }}>
                        Cancel the order
                    </Button>
                </Box>
            )}
        </>
    );
}

export default VerifyComplimentaryOrderPayment;
