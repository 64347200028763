import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Stack } from '@mui/material';
import axios from 'axios';

const OtpModal = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobileNumber?.length !== 10) {
      setError('Please enter a valid 10-digit mobile number.');
      return;
    }
    setError('');
    try {
      const response = await axios.get(`https://sms.zupzap.com/verification/get-otp`, {
        params: {
          mobile: mobileNumber,
        },
      });
      if (response?.data?.data?.record?.otp) {
        setOtp(response?.data?.data?.record?.otp);
        setError('');
      } else {
        setError('Failed to retrieve OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching OTP:', error?.response?.data?.data?.message);
      setError(error?.response?.data?.data?.message || 'An error occurred while fetching the OTP. Please try again later.');
    }
  };

  const isValidMobileNumber = mobileNumber.length === 10 && !isNaN(mobileNumber);

  return (
    <Box
      sx={{
        width: 420,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        p: 4,
        mx: 'auto',
        my: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        bgcolor: 'white',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 1.5,
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#003049',
          textTransform: 'uppercase',
          letterSpacing: '0.05rem',
        }}
      >
        OTP Verification
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mb: 3,
          textAlign: 'center',
          color: 'text.secondary',
          fontSize: '0.95rem',
        }}
      >
        Enter the customer's mobile number to receive the active OTP.
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TextField
          fullWidth
          label="Mobile Number"
          variant="outlined"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          error={!!error}
          helperText={error}
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!isValidMobileNumber}
          sx={{
            mb: 2,
            textTransform: 'uppercase',
            borderRadius: 12,
            fontWeight: 'bold',
            fontSize: '0.9rem',
            backgroundColor: isValidMobileNumber ? '#003049' : '#e0e0e0',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: isValidMobileNumber ? '#002437' : '#e0e0e0',
            },
            '&.Mui-disabled': {
              backgroundColor: '#b0bec5',
              color: '#ffffff',
            },
          }}
        >
          Get OTP
        </Button>
      </form>
      {otp && (
        <Stack
          direction="column"
          alignItems="center"
          spacing={1}
          sx={{
            mt: 3,
            p: 2,
            bgcolor: '#f9fafb',
            borderRadius: 2,
            width: '100%',
            textAlign: 'center',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#37474f',
              fontSize: '0.95rem',
            }}
          >
            Active OTP:
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#388e3c',
              letterSpacing: '0.1rem',
            }}
          >
            {otp}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default OtpModal;
