import AppLayout from "./AppLayout";
import { Route, Routes } from 'react-router-dom'
import OnlineOrders from "./screens/App/OnlineOrders";
import Orders from "./screens/App/Orders";
import Dashboard from "./screens/App/Dashboard";
import Login from "./screens/Auth/Login";
import AuthLayout from "./AuthLayout";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import OrderPayment from "./screens/App/OrderPayment";
import ManagerDashboard from "./screens/App/ManagerDashboard";
import Products from "./screens/App/Products";
import ProtectedRoutes from "./ProtectedRoutes"; 
import Inventory from "./screens/App/Inventory";
import Profile from "./screens/App/Profile";
import ResetPassword from "./screens/Auth/ResetPassword";
import ErrorScreen from "./screens/Auth/ErrorScreen";
import Reports from "./screens/App/Reports";
import OrdersReport from "./screens/App/OrdersReport";
import CriticalStockReport from "./screens/App/CriticalStockReport";
import PaymentTransactions from "./screens/App/PaymentTransactions";
import CreateOrder from "./screens/App/CreateOrder";
import ComplimentaryOrder from "./screens/App/ComplimentaryOrder";
import ComplimentaryOrderPayment from "./screens/App/ComplimentaryOrderPayment";
import VerifyComplimentaryOrderPayment from "./screens/App/verifyComplimentaryOrderPayment";
import CustomerSupport from "./screens/App/CustomerSupport";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<AuthLayout />}>
                <Route path='/' element={<Login />} />
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route path='/resetPassword' element={<ResetPassword />} />
            </Route>
            <Route path='/' element={<AppLayout />}>
                <Route element={<ProtectedRoutes />}>
                    <Route path='/onlineOrders' element={<OnlineOrders />} />
                    <Route path='/orders' element={<Orders />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/orderPayment' element={<OrderPayment />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/managerDashboard' element={<ManagerDashboard />} />
                    <Route path='/inventory' element={<Inventory />} />
                    <Route path='/reports' element={<Reports />} />
                    <Route path='/ordersReport' element={<OrdersReport />} />
                    <Route path='/criticalReport' element={<CriticalStockReport />} />
                    <Route path='/paymentTransactions' element={<PaymentTransactions />} />
                    <Route path='/createOrder' element={<CreateOrder />} />
                    <Route path='/complimentaryOrder' element={<ComplimentaryOrder />} />
                    <Route path='/complimentaryOrderPayment' element={<ComplimentaryOrderPayment />} />
                    <Route path='/verifyComplimentaryOrderPayment' element={<VerifyComplimentaryOrderPayment />} />
                    <Route path='/customerSupport' element={<CustomerSupport />} />
                </Route>
            </Route>
            <Route path='/errorScreen' element={<ErrorScreen />} />
        </Routes>
    )
}

export default AppRoutes;