import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Card, CardContent, Stack, Divider, Toolbar } from '@mui/material';
import { Person, Delete, FactCheck, Redeem } from '@mui/icons-material';

const CustomerSupport = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [customerData, setCustomerData] = useState(null);
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
            setError('Please enter a valid 10-digit mobile number.');
            return;
        }
        setError('');
        // Simulated API response
        const simulatedData = {
            customerDetails: {
                name: 'John Doe',
                mobile: '9876543210',
                email: 'john.doe@example.com',
                joiningDate: '2023-01-15',
                firstOrderedOutlet: 'Downtown Cafe',
                userLevel: 'Gold',
            },
            deletedAccounts: [
                { createdDate: '2022-10-01' },
                { createdDate: '2021-08-20' },
            ],
            otherFacts: {
                totalOrders: 25,
                cancelledOrders: 3,
                referredUsers: 5,
            },
            couponDetails: {
                joiningOffer: { usedDate: '2023-02-01', outlet: 'Downtown Cafe' },
                referralCoupons: [
                    { usedDate: '2023-03-10' },
                    { usedDate: '2023-04-05' },
                ],
            },
        };
        setCustomerData(simulatedData);
    };

    return (
        <Box sx={{ p: 4, bgcolor: '#f4f6f8', minHeight: '100vh' }}>
            <Toolbar />
            <Typography
                variant="h4"
                sx={{
                    mt: 3,
                    mb: 1,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#003049',
                    letterSpacing: '0.05rem',
                }}
            >
                Customer Complaint Resolution
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
                Enter the customer's mobile number to check their details and resolve complaints.
            </Typography>

            {/* Input Form */}
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '30px',
                }}
            >
                <TextField
                    label="Mobile Number"
                    variant="outlined"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    error={!!error}
                    helperText={error}
                    sx={{
                        mr: 2,
                        width: '300px',
                        bgcolor: '#ffffff',
                        borderRadius: 2,
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                        width: '150px',
                        bgcolor: '#003049',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        '&:hover': { bgcolor: '#002437' },
                    }}
                >
                    Submit
                </Button>
            </form>

            {/* Customer Details */}
            {customerData && (
                <Grid container spacing={4}>
                    {/* Customer Details Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Person color="primary" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003049' }}>
                                        Customer Details
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Stack direction="row" spacing={4}>
                                    <Typography variant="body1">Name: {customerData.customerDetails.name}</Typography>
                                    <Typography variant="body1">Mobile: {customerData.customerDetails.mobile}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
                                    <Typography variant="body1">Email: {customerData.customerDetails.email}</Typography>
                                    <Typography variant="body1">Joining Date: {customerData.customerDetails.joiningDate}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Deleted Accounts Section */}
                    {customerData.deletedAccounts.length > 0 && (
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 2,
                                    transition: 'transform 0.2s',
                                    '&:hover': { transform: 'scale(1.02)' },
                                }}
                            >
                                <CardContent>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Delete color="error" />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#d32f2f' }}>
                                            Deleted Accounts
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ my: 2 }} />
                                    {customerData.deletedAccounts.map((account, index) => (
                                        <Typography key={index} variant="body1">
                                            Account {index + 1} Created Date: {account.createdDate}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}

                    {/* Other Facts Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <FactCheck color="primary" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                                        Other Facts
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Stack direction="row" spacing={4}>
                                    <Typography variant="body1">Total Orders: {customerData.otherFacts.totalOrders}</Typography>
                                    <Typography variant="body1">Cancelled Orders: {customerData.otherFacts.cancelledOrders}</Typography>
                                    <Typography variant="body1">Referred Users: {customerData.otherFacts.referredUsers}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Coupon Details Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Redeem color="success" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388e3c' }}>
                                        Coupon Details
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    Joining Offer Used on: {customerData.couponDetails.joiningOffer.usedDate} at{' '}
                                    {customerData.couponDetails.joiningOffer.outlet}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    Referral Coupons Used:
                                </Typography>
                                <Stack direction="column" spacing={1}>
                                    {customerData.couponDetails.referralCoupons.map((coupon, index) => (
                                        <Typography key={index} variant="body1">
                                            Date {index + 1}: {coupon.usedDate}
                                        </Typography>
                                    ))}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default CustomerSupport;
