import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import OrdersList from '../../components/OrdersList';
import { KeyboardArrowLeft } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from "axios";
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

function ComplimentaryOrderPayment() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cartSummary, setCartSummary] = useState([]);
  const [items, setItems] = useState([]);
  const [remark, setRemark] = useState('');
  const [remarkError, setRemarkError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [hasCartItems, setHasCartItems] = useState(false);
  const token = localStorage.getItem("token");
  const outletId = localStorage.getItem("outletId");
  const [cartUpdated, setCartUpdated] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleModeSelect = (modeId) => {
    setSelectedMode(modeId);
    setPaymentEnabled(true);
  };

  const schema = Yup.object().shape({
    remark: Yup.string()
      .min(25, 'Remark must be at least 25 characters')
      .required('Remark is required'),
  });

  const validateRemark = async (value) => {
    try {
      await schema.validate({ remark: value });
      setRemarkError('');
      setIsButtonDisabled(false);
    } catch (error) {
      setRemarkError(error.message);
      setIsButtonDisabled(true);
    }
  };

  const handleRemarkChange = (event) => {
    const value = event.target.value;
    setRemark(value);
    validateRemark(value);
  };

  const handlePayment = () => {
    if (!isButtonDisabled) {
      navigate('/verifyComplimentaryOrderPayment', {
        state: { remark }
      });
    }
  };

  const fetchCartData = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}cart/userCart/${outletId}/PICKUP`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('asdcbaksdbckabsdcjhbasdvbasfvbkajsfvkabsv', response?.data);
      const totalQuantity = response?.data?.data?.cartItems?.reduce((acc, item) => acc + item?.quantity, 0) || 0;
      setItems(totalQuantity);
      setCartSummary(response?.data?.data);
      setCartUpdated((prev) => !prev);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      toast.error("Failed to load cart data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  return (
    <>
      <Box>
        <Grid container className="flex-section">
          <Grid item md={6} sx={{ backgroundColor: '#F6F6F6', minHeight: '100vh' }}>
            <Box sx={{ marginTop: '95px', padding: '10px 30px 40px 30px' }}>
              <Box sx={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <KeyboardArrowLeft sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '15px' }} onClick={() => navigate('/complimentaryOrder')} />
                <Typography sx={{ fontSize: '22px' }}>Order Details</Typography>
              </Box>
              <Box>
                <OrdersList cartUpdated={cartUpdated} setHasCartItems={setHasCartItems} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ marginTop: '95px', padding: '10px 30px 20px 30px' }}>
              <Typography sx={{ textAlign: 'center', fontSize: '22px' }}>Payment</Typography>
              <Grid container sx={{ padding: '0px 0 2px 0px' }}>
                <Grid item md={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Summary</Typography>
                  <Grid container sx={{ padding: '4px 0 4px 0' }}>
                    <Grid item md={10}>
                      <Typography className="onlineOrderFont" >
                        Item(s)
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end' }}>
                      <Typography className='onlineOrderFont'>
                        {items}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0' }}>
                    <Grid item md={9}>
                      <Typography className='onlineOrderFont'>
                        Total Taxable
                      </Typography>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'end' }}>
                      <Typography className='onlineOrderFont'>
                        ₹ {cartSummary?.cart?.totalTaxable?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0' }}>
                    <Grid item md={9}>
                      <Typography className='onlineOrderFont'>
                        Total Tax Amount
                      </Typography>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'end' }}>
                      <Typography className='onlineOrderFont'>
                        ₹ {cartSummary?.cart?.totalTaxAmount?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0' }}>
                    <Grid item md={9}>
                      <Typography className='onlineOrderFont'>
                        Total Sale Price
                      </Typography>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'end' }}>
                      <Typography className='onlineOrderFont'>
                        ₹ {cartSummary?.cart?.grandTotal?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0', borderBottom: '0.2px solid #D8D3CD' }}>
                    <Grid item md={9}>
                      <Typography className='onlineOrderFont'>
                        Discounts
                      </Typography>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'end' }}>
                      <Typography className='onlineOrderFont' sx={{ color: 'darkgreen' }}>
                        - ₹ {cartSummary?.cart?.totalTaxable?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0' }}>
                    <Grid item md={9}>
                      <Typography sx={{ fontSize: '22px' }}>
                        Total Payable
                      </Typography>
                    </Grid>
                    <Grid item md={3} sx={{ textAlign: 'end' }}>
                      <Typography sx={{ fontSize: '22px' }}>
                        ₹ 0
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: '0px 30px 0 30px' }}>
              <Typography variant='h6' color={'#003049'}>Reason for the order</Typography>
              <TextField
                fullWidth
                multiline
                minRows={7}
                placeholder='Type your remarks here (Min. 25 characters)'
                value={remark}
                onChange={handleRemarkChange}
                error={!!remarkError}
                helperText={remarkError}
              />
            </Box>
            <Box
              onClick={handlePayment}
              className={isButtonDisabled ? 'cursor-disabled' : 'cursor-p'}
              sx={{
                backgroundColor: isButtonDisabled ? '#ccc' : '#003049',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '67%',
                height: '43px',
                alignItems: 'center', margin: '15px 0px 0 82px', padding: '18px', borderRadius: '30px', color: '#fff', cursor: isButtonDisabled ? 'not-allowed' : 'pointer' }}
            >
              <Stack direction={'row'}>
                <Typography>Place the order</Typography>
                <ChevronRightIcon />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ComplimentaryOrderPayment;