import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Stack, CircularProgress } from "@mui/material";
import { Add, CancelOutlined, Remove } from "@mui/icons-material";
import axios from "axios";
import { API } from "../utils/Api";
import { toast } from 'react-toastify';
import Images from "../utils/Images";

function OrdersList({ cartUpdated, setHasCartItems }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const outletId = localStorage.getItem("outletId");
  const orderType = localStorage.getItem("selectedOrderType");

  const fetchCartData = async () => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}cart/userCart/${outletId}/PICKUP`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(response.data?.data?.cartItems || []);
      setHasCartItems(response?.data?.data?.cartItems?.length > 0);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      toast.error("Failed to load cart data");
    } finally {
      setLoading(false);
    }
  };

  const handleIncreaseQuantity = async (cartItemId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    await updateQuantityOnServer(cartItemId, newQuantity);
  };

  const handleDecreaseQuantity = async (cartItemId, currentQuantity) => {
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      await updateQuantityOnServer(cartItemId, newQuantity);
    } else {
      await deleteCartItem(cartItemId);
    }
  };

  const updateQuantityOnServer = async (cartItemId, newQuantity) => {
    try {
      const response = await axios.put(
        `${API.BASE_URL}cart`,
        {
          cartItems: [{ _id: cartItemId, quantity: newQuantity }],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Item quantity updated in your cart");
      fetchCartData();
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Failed to update quantity");
    }
  };

  const deleteCartItem = async (cartItemId) => {
    try {
      const response = await axios.delete(`${API.BASE_URL}cart`, {
        data: { cartItemIds: [cartItemId] },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Item removed from your cart");
      fetchCartData();
    } catch (error) {
      console.error("Error deleting cart item:", error);
      toast.error("Failed to remove item from cart");
    }
  };

  const calculateTotalPrice = (order) => {
    let totalPrice = order.product.offerPrice * order.quantity;
    if (order.addons && order.addons.length > 0) {
      totalPrice += order.addons.reduce(
        (sum, addon) => sum + (addon.offerPrice || 0) * order.quantity,
        0
      );
    }
    return totalPrice.toFixed(2).replace(/\.?0*$/, "");
  };

  useEffect(() => {
    fetchCartData();
  }, [cartUpdated]);

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
        <Typography mt={2}>Loading cart...</Typography>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="space-between">
      {orders && orders?.length > 0 ? (
        orders.map((order, index) => (
          <Grid
            item
            key={order?._id}
            md={12}
            className="shadowed-box"
            sx={{
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "#fff",
              margin: "10px 0 10px 0",
            }}
          >
            <Grid container sx={{ padding: "4px 0 4px 0" }}>
              <Grid item md={11} sx={{ display: "flex", alignItems: "center" }}>
                <Typography className="onlineOrderFont">
                  {order?.product?.name} - ₹{calculateTotalPrice(order)}
                </Typography>
              </Grid>
            </Grid>

            {order?.addons && order?.addons?.length > 0 && (
              <Grid container>
                {order?.addons?.map((addon, addonIndex) => (
                  <Grid key={addonIndex} item className="attributes">
                    {addon?.selectedValue
                      ? `${addon?.selectedValue} ₹${addon?.offerPrice || 0}`
                      : "No Addon"}
                  </Grid>
                ))}
              </Grid>
            )}

            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ padding: "20px 0 10px 0", alignItems: "center" }}
            >
              <CancelOutlined
                sx={{ color: "red", cursor: "pointer" }}
                onClick={() => deleteCartItem(order?._id)}
              />

              <Box>
                <Stack direction={"row"} spacing={1}>
                  <Remove
                    style={{
                      fontSize: "2rem",
                      color: "#fff",
                      backgroundColor: "#003049",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleDecreaseQuantity(order?._id, order?.quantity)
                    }
                  />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      width: "30px",
                      textAlign: "center",
                    }}
                  >
                    {order?.quantity}
                  </Stack>
                  <Add
                    style={{
                      fontSize: "2rem",
                      color: "#fff",
                      backgroundColor: "#003049",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleIncreaseQuantity(order?._id, order?.quantity)
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        ))
      ) : (
        <Box sx={{ minHeight: '100%', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px', marginTop: '10px', textAlign: 'center' }} className='custom-scrollbar'>
          <img src={Images.emptyCartImg} alt='no-orders-available' style={{ width: '90%', marginTop: '150px' }} />
          <Typography variant="h6" sx={{ marginTop: '10px' }}>No items in cart</Typography>
        </Box>
      )}
    </Grid>
  );
}

export default OrdersList;
