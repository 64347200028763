import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import LanguageIcon from '@mui/icons-material/Language';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from 'axios';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { API } from '../utils/Api';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../src/store/Login/loginSlice';
import { AssessmentOutlined } from '@mui/icons-material';

const drawerWidth = 130;

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [selectedRoute, setSelectedRoute] = React.useState('./onlineOrders');
  const userData = useSelector((state) => state.login.user); 
  const dispatch = useDispatch();
  const storedOutletData = localStorage.getItem('outletData');
  const outletData = JSON.parse(storedOutletData);

  const userName = userData && userData.user ? userData.user.name : '';

  const menuItems = [
    { text: 'Orders', icon: <LanguageIcon />, route: './onlineOrders' },
    // { text: 'Orders', icon: <DataUsageOutlinedIcon />, route: './orders' },
    { text: 'Dashboard', icon: <DashboardIcon />, route: userRole === 'CASHIER' ? './dashboard' : './managerDashboard' },
    ...(!outletData?.isEventOutlet ? (
      userRole === 'MANAGER' || userRole === "CAFE-MANAGER"
        ? [{ text: 'Reports', icon: <AssessmentOutlined />, route: './reports' }]
        : []
    ) : []),
    ...(!outletData?.isEventOutlet ? [
      { text: 'Products', icon: <CategoryOutlinedIcon />, route: './products' }
    ] : [])
  ];

  React.useEffect(() => {
    navigate(selectedRoute);
  }, []);

  const handleItemClick = (route) => {
    navigate(route);
    setSelectedRoute(route);
  };

  const handleButtonClick = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}outlet/user/signout`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      }); 
      if ( response.data.status === true ) {
        // console.log('Logout successful:', response.data);
        toast.success("Logout successful!", {
          autoClose: 3000,
        });
        navigate('/', { state: { message: true } });
        localStorage.clear();
        localStorage.setItem("isLoggedIn", 'false');
        dispatch(logout());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#0C0F14', overflowY: 'hidden', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
      >
        <NavBar />
      </AppBar>
      <Drawer
        sx={{
          bgcolor: '#000',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            bgcolor: '#0C0F14',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ flexDirection: 'column', marginTop: '15px' }}>
          <Typography sx={{ color: '#fff' }} variant="h5">
            Xpanse
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph>
            POS
          </Typography>
        </Toolbar>
        <List className='sideBar' sx={{}}>
          {menuItems.map((item, index) => (
            <ListItem className='sideBar-btnContainer' key={item.text} disablePadding>
              <ListItemButton
                className='sideBar-button'
                onClick={() => handleItemClick(item.route)}
                sx={{
                  backgroundColor: selectedRoute === item.route ? '#003049' : '#fff',
                  '&:hover': {
                    backgroundColor: selectedRoute === item.route ? '#003049' : '#fff',
                  },
                }}
              >
                <ListItemIcon sx={{ color: selectedRoute === item.route ? '#fff' : '#000' , justifyContent: 'center' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className='sideBar-btnText'
                  primaryTypographyProps={{ sx: { [theme.breakpoints.down('xs')]: { fontSize: '8px' }, [theme.breakpoints.between('sm', 'md')]: { fontSize: '8px' }, [theme.breakpoints.up('md')]: { fontSize: '11px' },} }}
                  sx={{ margin: '0px', color: selectedRoute === item.route ? '#fff' : '#000', justifyContent: 'center' }}
                  primary={item.text}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem sx={{ }}>
            <ListItemButton onClick={() => { navigate('/profile'); setSelectedRoute('') }} sx={{ backgroundColor: '#0C0F14', borderRadius: '4px',  margin: '5px 2px 0px 2px', alignSelf: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
              <ListItemIcon sx={{ color: '#fff', justifyContent: 'center' }}>
                <AccountCircleOutlinedIcon sx={{ fontSize: '35px'}} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '16px' }}} sx={{ color: '#fff', justifyContent: 'center' }}>
                {userName}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem className='sideBar-btnContainer' disablePadding sx={{ fontSize: '12px', margin: '0px' }}>
            <ListItemButton
              onClick={handleButtonClick}
              className='sideBar-button'
              sx={{
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#003049',
                },
              }} 
            >
              <ListItemIcon sx={{ color: '#000', justifyContent: 'center', '&:hover': { color: '#fff' } }}>
                <LoginOutlinedIcon />
              </ListItemIcon>
              <ListItemText 
                className='sideBar-btnText'
                primaryTypographyProps={{ sx: { '&:hover': { color: '#fff' }, [theme.breakpoints.down('xs')]: { fontSize: '8px' }, [theme.breakpoints.between('sm', 'md')]: { fontSize: '8px' }, [theme.breakpoints.up('md')]: { fontSize: '11px' },} }}
              >
                Logout  
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
